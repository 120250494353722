:root {
	--ion-background-color: #f5f5f5;
	--ion-background-color-rgb: 231,231,231;

	--ion-background-color-step-50: #dbdbdb;
	--ion-background-color-step-100: #d0d0d0;
	--ion-background-color-step-150: #c4c4c4;
	--ion-background-color-step-200: #b9b9b9;
	--ion-background-color-step-250: #adadad;
	--ion-background-color-step-300: #a2a2a2;
	--ion-background-color-step-350: #969696;
	--ion-background-color-step-400: #8b8b8b;
	--ion-background-color-step-450: #7f7f7f;
	--ion-background-color-step-500: #747474;
	--ion-background-color-step-550: #686868;
	--ion-background-color-step-600: #5c5c5c;
	--ion-background-color-step-650: #515151;
	--ion-background-color-step-700: #454545;
	--ion-background-color-step-750: #3a3a3a;
	--ion-background-color-step-800: #2e2e2e;
	--ion-background-color-step-850: #232323;
	--ion-background-color-step-900: #171717;
	--ion-background-color-step-950: #0c0c0c;

	--ion-text-color: #000000;
	--ion-text-color-rgb: 0,0,0;

	--ion-text-color-step-50: #0c0c0c;
	--ion-text-color-step-100: #171717;
	--ion-text-color-step-150: #232323;
	--ion-text-color-step-200: #2e2e2e;
	--ion-text-color-step-250: #3a3a3a;
	--ion-text-color-step-300: #454545;
	--ion-text-color-step-350: #515151;
	--ion-text-color-step-400: #5c5c5c;
	--ion-text-color-step-450: #686868;
	--ion-text-color-step-500: #747474;
	--ion-text-color-step-550: #7f7f7f;
	--ion-text-color-step-600: #8b8b8b;
	--ion-text-color-step-650: #969696;
	--ion-text-color-step-700: #a2a2a2;
	--ion-text-color-step-750: #adadad;
	--ion-text-color-step-800: #b9b9b9;
	--ion-text-color-step-850: #c4c4c4;
	--ion-text-color-step-900: #d0d0d0;
	--ion-text-color-step-950: #dbdbdb;

	--ion-color-primary: #00b8c1;
	--ion-color-primary-rgb: 0,184,193;
	--ion-color-primary-contrast: #000000;
	--ion-color-primary-contrast-rgb: 0,0,0;
	--ion-color-primary-shade: #00a2aa;
	--ion-color-primary-tint: #1abfc7;

	--ion-color-tertiary: #FAAD52;
	--ion-color-tertiary-rgb: 250,173,82;
	--ion-color-tertiary-contrast: #000000;
	--ion-color-tertiary-contrast-rgb: 0,0,0;
	--ion-color-tertiary-shade: #dc9848;
	--ion-color-tertiary-tint: #fbb563;

	--ion-color-success: #00ff00;
	--ion-color-success-rgb: 0,255,0;
	--ion-color-success-contrast: #000000;
	--ion-color-success-contrast-rgb: 0,0,0;
	--ion-color-success-shade: #00e000;
	--ion-color-success-tint: #1aff1a;
}
.ion-palette-dark, .ion-palette-dark.ios  {
  --ion-background-color: #111;
	--ion-background-color-rgb: 17,17,17;

	--ion-text-color: #fff;
	--ion-text-color-rgb: 255,255,255;

	--ion-text-color-step-50: #f3f3f3;
	--ion-text-color-step-100: #e7e7e7;
	--ion-text-color-step-150: #dbdbdb;
	--ion-text-color-step-200: #cfcfcf;
	--ion-text-color-step-250: #c4c4c4;
	--ion-text-color-step-300: #b8b8b8;
	--ion-text-color-step-350: #acacac;
	--ion-text-color-step-400: #a0a0a0;
	--ion-text-color-step-450: #949494;
	--ion-text-color-step-500: #888888;
	--ion-text-color-step-550: #7c7c7c;
	--ion-text-color-step-600: #707070;
	--ion-text-color-step-650: #646464;
	--ion-text-color-step-700: #585858;
	--ion-text-color-step-750: #4d4d4d;
	--ion-text-color-step-800: #414141;
	--ion-text-color-step-850: #353535;
	--ion-text-color-step-900: #333333;
	--ion-text-color-step-950: #1d1d1d;

	--ion-background-color-step-50: #1d1d1d;
	--ion-background-color-step-100: #292929;
	--ion-background-color-step-150: #353535;
	--ion-background-color-step-200: #414141;
	--ion-background-color-step-250: #4d4d4d;
	--ion-background-color-step-300: #585858;
	--ion-background-color-step-350: #646464;
	--ion-background-color-step-400: #707070;
	--ion-background-color-step-450: #7c7c7c;
	--ion-background-color-step-500: #888888;
	--ion-background-color-step-550: #949494;
	--ion-background-color-step-600: #a0a0a0;
	--ion-background-color-step-650: #acacac;
	--ion-background-color-step-700: #b8b8b8;
	--ion-background-color-step-750: #c4c4c4;
	--ion-background-color-step-800: #cfcfcf;
	--ion-background-color-step-850: #dbdbdb;
	--ion-background-color-step-900: #e7e7e7;
	--ion-background-color-step-950: #f3f3f3;

	--ion-color-tertiary: #FAAD52;
	--ion-color-tertiary-rgb: 250,173,82;
	--ion-color-tertiary-contrast: #000000;
	--ion-color-tertiary-contrast-rgb: 0,0,0;
	--ion-color-tertiary-shade: #dc9848;
	--ion-color-tertiary-tint: #fbb563;

  ion-content::part(background) {
    --background: #111;
  }
}
:host {
  background: var(--ion-background-color);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.materialIcon {
  html.ion-palette-dark & {
  }
}

ion-item, ion-item.tipz {
  --padding-start: 0 !important;
  --padding-end: 0 !important;
  --padding-top: 0 !important;
  --padding-bottom: 0 !important;
  --inner-padding-start: 0 !important;
  --inner-padding-end: 0 !important;
  --inner-padding-top: 0 !important;
  --inner-padding-bottom: 0 !important;
  --background: none !important;
}

ion-datetime-button::part(native) {
  min-height: 2.5em;
}

.tipz ion-label {
  margin-bottom: 2px !important;
  margin-top: 0 !important;
  font-size: 14px !important;

  &.md {
    transform: none !important;
  }
}

.tipz ion-input, .tipz ion-textarea, .tipz ion-select, .ion-input {
  --padding-start: 10px !important;
  --padding-end: 10px !important;
  border: 1px solid black;
  border-radius: 3px;
  background: white;
  html.ion-palette-dark & {
    border: 1px solid #ccc;
    background: var(--ion-background-color-step-100);
  }
  &.hasError, html.ion-palette-dark &.hasError {
    border: 1px solid #f62020;
  }
  min-height: 2.5em;
}

ion-modal {
  --background: #55555500 !important;
}

.loadingBackground {
  background: rgb(253,187,45);
  background: radial-gradient(circle, rgba(253,187,45,1) 0%, rgba(34,193,195,1) 100%);
}

ion-card {
  margin-top: 0px;
  margin-bottom: 0px;
}

.tipzTabs {
  background: var(--ion-color-light);
}

.activeTab {
  color: var(--ion-color-primary);
}

.tipzTime {
  background: var(--ion-background-color-step-200, #edeef0);
  &:active {
    color: var(--ion-color-primary);
  }
  &:hover {
    color: var(--ion-color-primary);
  }
}

* {
  border-style: none;
}
.modalInput {
  background: var(--ion-color-light);
  & input, ::after {
    background: var(--ion-background-color-step-300, #edeef0);
    color: var(--ion-color-dark);
  }
}

.tipzButton {
  cursor: pointer;
  padding: 8px 12px;
  margin: 2px;
  border-radius: 10px;
  background: var(--ion-color-primary);

  &.tipzColorTertiary {
    background: var(--ion-color-tertiary);
    &:active {
      background: var(--ion-color-tertiary-shade);
    }
  }

  &:hover {
    //background: var(--ion-color-primary-tint);
  }
  &:active {
    background: var(--ion-color-primary-shade);
  }
  &.tipzSuccess {
    cursor: pointer;
    background: var(--ion-color-success);
    &:hover {
      //background: var(--ion-color-success-tint);
    }
    &:active {
      background: var(--ion-color-success-shade);
    }
    color: black;
  }
  &.tipzError {
    cursor: pointer;
    background: var(--ion-color-danger);
    &:hover {
      //background: var(--ion-color-danger-tint);
    }
    &:active {
      background: var(--ion-color-danger-shade);
    }
  }
}

.tipzCard {
  background: var(--ion-background-color-step-100);
  .readonly {
    background: var(--ion-background-color-step-200);
  }
}
html.ion-palette-dark .tipzCard ion-input {
  background: var(--ion-background-color-step-200);
}
.tipzCardSubtitle {
  color: var(--ion-text-color-step-400);
}

.action-sheet-button.sc-ion-action-sheet-ios {
  background: white;
}
html.ion-palette-dark .action-sheet-button.sc-ion-action-sheet-ios {
  background: var(--ion-background);
}
.action-sheet-cancel.sc-ion-action-sheet-ios::after {
  background: white;
}
html.ion-palette-dark .action-sheet-cancel.sc-ion-action-sheet-ios::after {
  background: var(--ion-background-color-step-100);
}
.action-sheet-button.sc-ion-action-sheet-ios {
  color: var(--ion-text-color);
}

.tipzCode {
  border: 1px solid var(--ion-background-color-step-700);
  background: white;
  color: var(--ion-background-color-step-950);
  html.ion-palette-dark & {
    background: var(--ion-background-color-step-200);
  }
}

.tipzHours {
  color: #009999;
  font-weight: bold;
  html.ion-palette-dark & {
    color: #00ffff;
  }
}

/*ion-modal::part(content) {
  width: 100vw;
  height: 100vh;
}*/

ion-checkbox.tipzMoney[checked] {
  &::part(container) {
    background-color: #00aa00;
    border-color: #00aa00;
  }
  html.ion-palette-dark &::part(container) {
    background-color: #00ff00;
    border-color: #00ff00;
  }
  &::part(mark) {
    stroke: #000;
  }
}

/* trying to make this match the bg color of ion-chip */
ion-datetime-button::part(native) {
  background: var(--ion-background-color-step-100);
  html.ion-palette-dark & {
    background: var(--ion-background-color-step-150);
  }

  .tipzCard & {
    background: var(--ion-background-color-step-300);
  }
  html.ion-palette-dark .tipzCard & {
    background: var(--ion-background-color-step-300);
  }
}

.tipzTodayMarker {
  background: var(--ion-color-primary-shade);
  color: white;
}

.tipzMonthCalendarLabel {
  color: var(--ion-text-color-step-700);
}

.tipzLoginDisplay {
  background: var(--ion-color-primary);
  color: var(--ion-color-primary-contrast);
}

.tipzMoney {
  color: #00ff00;
  text-shadow: 1px 0px 2px #111, 0px 1px 2px #111, -1px 0px 2px #111, 0px 1px 2px #111;
  font-weight: bold;
  /*color: #19b682;*/
  html.ion-palette-dark & {
    color: #00ff00;
  }
}

.tipzGreen {
  color: #008800;
  html.ion-palette-dark & {
    color: #00ff00;
  }
}

.tipzPrimaryColor {
  color: var(--ion-color-primary);
  font-weight: bold;
  /*color: #1b66e7;*/
  &:hover {
    color: var(--ion-color-primary-shade);
  }
  html.ion-palette-dark & {
    color: var(--ion-color-primary-shade);
  }
}

.tipzTertiaryColor {
  color: var(--ion-color-tertiary);
  &:hover {
  }
  html.ion-palette-dark & {
    color: var(--ion-color-tertiary);
  }
}

.tipzIonFont {
  font-family: var(--ion-font-family);
}



